import './Home.scss';
import SectionHeading from '../../Components/SectionHeading/SectionHeading.js';

const pianowhitekeys = [
  {key:"A"},{key:"B"},{key:"C"},{key:"D"},{key:"E"},{key:"F"},{key:"G"},
  {key:"A"},{key:"B"},{key:"C"},{key:"D"},{key:"E"},{key:"F"},{key:"G"},
  {key:"A"},{key:"B"},{key:"C"},{key:"D"},{key:"E"},{key:"F"},{key:"G"},
  {key:"A"},{key:"B"},{key:"C"},{key:"D"},{key:"E"},{key:"F"},{key:"G"},
  {key:"A"},{key:"B"},{key:"C"},{key:"D"},{key:"E"},{key:"F"},{key:"G"},
  {key:"A"},{key:"B"},{key:"C"},{key:"D"},{key:"E"},{key:"F"},{key:"G"},
  {key:"A"},{key:"B"},{key:"C"},{key:"D"},{key:"E"},{key:"F"},{key:"G"},
  {key:"A"},{key:"B"},{key:"C"}
];
const pianoblackkeys = [
  /*
    't' stands for 'transparent'
    's' stands for 'sharp'
  */
  {key:"t"},
  {key:"As"},{key:"t"},{key:"Cs"},{key:"Ds"},{key:"t"},{key:"Fs"},{key:"Gs"},
  {key:"As"},{key:"t"},{key:"Cs"},{key:"Ds"},{key:"t"},{key:"Fs"},{key:"Gs"},
  {key:"As"},{key:"t"},{key:"Cs"},{key:"Ds"},{key:"t"},{key:"Fs"},{key:"Gs"},
  {key:"As"},{key:"t"},{key:"Cs"},{key:"Ds"},{key:"t"},{key:"Fs"},{key:"Gs"},
  {key:"As"},{key:"t"},{key:"Cs"},{key:"Ds"},{key:"t"},{key:"Fs"},{key:"Gs"},
  {key:"As"},{key:"t"},{key:"Cs"},{key:"Ds"},{key:"t"},{key:"Fs"},{key:"Gs"},
  {key:"As"},{key:"t"},{key:"Cs"},{key:"Ds"},{key:"t"},{key:"Fs"},{key:"Gs"},
  {key:"As"},
  {key:"t"},
];

const pianowhitekeysmobile = [
  {key:"C"},{key:"D"},{key:"E"},{key:"F"},{key:"G"},{key:"A"},{key:"B"},
];
const pianoblackkeysmobile = [
  /*
    't' stands for 'transparent'
    's' stands for 'sharp'
  */
  {key:"t"},
  {key:"Cs"},{key:"Ds"},{key:"t"},{key:"Fs"},{key:"Gs"},{key:"As"},
];



function ShowWhiteKeys() {
  return (
    <div className="whitekeys_container">
      <div className="whitekeys_container_contents">
        {pianowhitekeys.map(i => (
          <div className="whitekeys_container_key" />
        ))}
      </div>
    </div>
  );
}

function ShowBlackKeys() {
  return (
    <div className="blackkeys_container">
      <div className="blackkeys_container_contents">
        {pianoblackkeys.map(i => (
          <div className={"blackkeys_container_key" + i.key} />
        ))}
      </div>
    </div>
  );
}


function ShowWhiteKeysMobile() {
  return (
    <div className="whitekeys_container">
      <div className="whitekeys_container_contents">
        {pianowhitekeysmobile.map(i => (
          <div className="whitekeys_container_key" />
        ))}
      </div>
    </div>
  );
}

function ShowBlackKeysMobile() {
  return (
    <div className="blackkeys_container">
      <div className="blackkeys_container_contents">
        {pianoblackkeysmobile.map(i => (
          <div className={"blackkeys_container_key" + i.key} />
        ))}
      </div>
    </div>
  );
}




function Home() {
  return (
    <div className="home_parent">

      <div className="home_desktop">
        <div className="home_text">contact: jnnglrlol@gmail.com</div>
        <ShowWhiteKeys />
        <ShowBlackKeys />
      </div>

      <div className="home_mobile">
        <div className="home_text">contact: jnnglrlol@gmail.com</div>
        <ShowWhiteKeysMobile />
        <ShowBlackKeysMobile />
      </div>


    </div>
  )
}

export default Home;







/*
import './Home.scss';
import SectionHeading from '../../Components/SectionHeading/SectionHeading.js';

const pianowhitekeys = [
  {key:"C"},
  {key:"D"},
  {key:"E"},
  {key:"F"},
  {key:"G"},
  {key:"A"},
  {key:"B"},
];
const pianoblackkeys = [
  {key:"C#"},
  {key:"D#"},
  {key:"F#"},
  {key:"G#"},
  {key:"A#"},
];

function ShowWhitePianoKeys() {
  return (
    <div className="whitepianokeys_container">
      <div className="whitepianokeys_container_contents">
        {pianowhitekeys.map(i => (
          <span className="whitepianokeys_container_key"><div className="fontsize2">{i.key}</div></span>
        ))}
      </div>
    </div>
  );
}


function Home() {
  return (
    <div className="piano_parent">
      <div className="piano_sectionheading">
        <SectionHeading text="This is our world" />
      </div>
      <ShowWhitePianoKeys />

      <div className="blackpianokeys_container">
        <div className="blackpianokeys_container_contents">

          <span className="blackpianokeys_container_key1"><div className="fontsize1" style={{color:"#FFFFFF"}}>C#</div></span>
          <span className="blackpianokeys_container_key2"><div className="fontsize1" style={{color:"#FFFFFF"}}>D#</div></span>
          <span className="blackpianokeys_container_key3"><div className="fontsize1" style={{color:"#FFFFFF"}}>F#</div></span>
          <span className="blackpianokeys_container_key4"><div className="fontsize1" style={{color:"#FFFFFF"}}>G#</div></span>
          <span className="blackpianokeys_container_key5"><div className="fontsize1" style={{color:"#FFFFFF"}}>As</div></span>

        </div>
      </div>

    </div>
  )
}

export default Home;
*/
