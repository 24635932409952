import React from 'react';
import './SectionHeading.scss';

function SectionHeading(props) {
  return (
    <div className="sectionheading">
      <div className="fontsize2" style={{color:props.color},{fontWeight: "bold"}}>{props.text}</div>
    </div>
  );
}

export default SectionHeading;
