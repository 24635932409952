import './App.scss';
import Home from './Pages/Home/Home.js';

function App() {
  return (
    <div className="app">

      <Home />

    </div>
  );
}

export default App;
